import React, { useState } from 'react';
import { useEffect } from 'react';
import { decode } from 'html-entities';
import { $ } from 'react-jquery-plugin';
import { addEmergencyContacts, getEmergencyConatcts, getProfessional, getSpecificUser, uploadCertificatesAPI, uploadImage } from '../../../functions/api';
import Calendar from 'react-calendar';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserProfileData, getOccupant, updateHeadOfficeProfile, updateUserData, upDateOccupants, addProfessionalDevelop, updateProfessionalProfile, uploadDocument, updateProfessionalDevelopmentLog, deleteProfessioanlDevelopLogs } from '../../../functions/api';
import HeadOfficeMainHeader from '../../../commonComponents/HeadOfficeMainHeader/HeadOfficeMainHeader';
import LoaderComponent from '../../../commonComponents/LoaderComponent/LoaderComponent'
import { baseUrl } from "../../../config";
import pdfIcons from '../../../images/pdfImage.png';
import excelIcons from '../../../images/excelImage.png';
import docIcons from '../../../images/docImage.png';
import ImageIcon from '../../../images/image_icon.png';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";
import moment from "moment";
import Moment from "react-moment";
import DummyImage from '../../../images/user2.png'

const EditHeadOfficeProfilePage = (props) => {


  let history = useHistory();
  const uid = props.match.params.id;
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [contactNumber, setContactNumber] = useState();
  const [profileId, setProfileId] = useState();
  const [userRole, setUserRole] = useState("");
  const store = useSelector((state) => state.auth);
  const [commencementDate, setCommencementDate] = useState();
  const [ceasedDate, setCeasedDate] = useState("");
  const [commenceCalendar, setCommenceCalendar] = useState(false);
  const [ceaseCalendar, setCeasecalendar] = useState(false);
  const [profileDob, setProfileDob] = useState();
  const [profileDobCal, setProfileDobCal] = useState(false);
  const [homeNumber, setHomeNumber] = useState('');
  const [suburbName, setSuburbName] = useState('');

  const [firstAidCourse, setFirstAidCourse] = useState();
  const [cprCourse, setCprCourse] = useState();
  const reminder = ["8 Weeks", "6 Weeks", "4 Weeks", "2 Weeks", "1 Week", "1 Day"]
  const [firstAidCourseReminder, setFirstAidCourseReminder] = useState('')
  const [crpCourseReminder, setCrpCourseReminder] = useState('')
  const [firstAidCompletionDate, setFirstAidCompletionDate] = useState('');
  const [cprCourseCompletionDate, setCprCourseCompletionDate] = useState('');
  const [firstAidCal, setfirstAidCal] = useState(false);
  const [cprCourseCal, setcprCourseCal] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [userPicture, setUserPicture] = useState();
  const [username, setUsername] = useState('')

  const [blueCard, setBlueCard] = useState();
  const [blueCardSignedDate, setBlueCardSignedDate] = useState();
  const [blueCardSignedCal, setBlueCardSignedCal] = useState(false);
  const [blueCardCompletionDate, setBlueCardCompletionDate] = useState();
  const [blueCardCompletionCal, setBlueCardCompletionCal] = useState(false);
  const [ajaxLoader, setAjaxLoader] = useState(true);

  // New Fields
  const [blueCardReminder, setBlueCardReminder] = useState('');

  const [firstAidExpiry, setFirstAidExpiry] = useState('')
  const [cprExpiry, setCprExpiry] = useState('')

  const [firstAidExpiryCalendar, setFirstAidExpiryCalendar] = useState(false)
  const [cprExpiryCalendar, setcprExpiryCalendar] = useState(false)
  // For Certificates 
  const [firstAidCertificate, setFirstAidCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [cprCertificate, setCprCertificate] = useState([{ value: '', id: '', url: '' }]);
  const [dateField, setDateField] = useState('')
  //Post code & Street Name--New Field
  const [postCode, setPostCode] = useState('');
  const [street, setStreet] = useState('');
  //Street Number & Unit Number-- New Field
  const [streetNumber, setStreetNumber] = useState('');
  const [unitNumber, setUnitNumber] = useState('');
  //Business Info Role New Field
  const [businessRole, setBusinessRole] = useState('');

  //Issue Date
  const [qualificationIssueDate, setQualificationIssueDate] = useState()

  //Qualification
  const [qualificationDetail, setQualificationDetail] = useState([{
    qualification_detail: '',
    issue_date: '',
    on_calender: false
  },])
  const [qualificationCount, setQualificationCount] = useState(1)

  //Const Check
  const [editableData, setEditableData] = useState([])
  //--------------------------STATE FOR pdl -------------------
  const [isEditPDL, setIsEditPDL] = useState(false)

  const [professionalDevelop, setProfessionalDevelop] = useState([]);
  const [professional_time, set_professional_time] = useState('');
  const [error, setError] = useState({
    methodError: ''
  });
  const [
    openProfessionalDevelopmentModal,
    setOpenProfessionalDevelopmentModal,
  ] = useState(false);
  //PD Certificate State
  const [uploadCertificate, setUploadCertificate] = useState([])

  let initialPDStates = {
    date: "",
    showDateCalender: false,
    method: "",
    showMethod: false,
    courseName: "",
    organisation: "",
    duration: "",
    showDurationCalender: false,
    certificate: [],
    fid: ''
  }
  const [professionalDevelopmentStates, setProfessionalDevelopmentStates] = useState(initialPDStates);
  const professionalDevelopmentMethods = [
    "webinar",
    "reading",
    "workshop",
    "conference",
    "meeting",
    "podcast"
  ];


  const [contacts, setContacts] = useState([{ field_title:"",
   field_phone:""}, { field_title:"",
     field_phone:""}]);
  const [medicalConditions, setMedicalConditions] = useState([""]);

  useEffect(() => {
    getProfessionalDevelop();
  }, [store.userRole, professionalDevelopmentStates.certificate.length, uid]);


  useEffect(() => {

    $(".accordion-content:not(:first-of-type)").css("display", "none");
    $(".js-accordion-title:first-of-type").addClass("open");

    $(".js-accordion-title").click(function () {
      $(".open").not(this).removeClass("open").next().slideUp(300);
      $(this).toggleClass("open").next().slideToggle(300);
    });


    $(function () {
      $('.mdc-text-field--outlined .mdc-text-field__input,.material-textfield textarea').on('focusout', function () {
        if ($(this).val().length > 0) {
          $(this).addClass('active');
        } else {
          $(this).removeClass('active');
        }
      })
    })

    //Dropdown toggle
    $(function () {
      $('.dropdown-toggle').click(function () {
        $(this).next('.dropdown').slideToggle();
      });

      $(document).click(function (e) {
        var target = e.target;
        if (!$(target).is('.dropdown-toggle') && !$(target).parents().is('.dropdown-toggle')) {
          $('.dropdown').slideUp();
        }
      });
    });


    async function getAllInitialData() {

      let responseData = await getEmergencyConatcts(uid)
      let filteredArray = responseData?.data?.data && responseData?.data?.data.length > 0 ? responseData?.data?.data.filter(item => item.field_title !== '' && item.field_phone !== '') :
        [{
          field_title: "",
          field_phone: ""
        }, {
          field_title: "",
          field_phone: ""
        }];
      if (filteredArray.length == 1) {
        filteredArray.push({ field_title: "", field_phone: "" })
      }
      setContacts(filteredArray)
      // let uid = window.localStorage.getItem('uid');
      let auth_token = window.localStorage.getItem('auth_token');
      let user_response = await getSpecificUser(uid, auth_token);
      if (store.userRole !== undefined) {
        setUserRole(store.userRole);
      }
      if (user_response.status === 200) {
        setAjaxLoader(false);
        if (user_response.data.data !== '' && user_response.data.data !== undefined) {

          if (user_response.data.data.field_first_name !== undefined && user_response.data.data.field_first_name.length > 0) {
            if (user_response.data.data.field_last_name !== undefined && user_response.data.data.field_last_name.length > 0) {
              let lname = user_response.data.data.field_last_name[0].value
              setUsername(user_response.data.data.field_first_name[0].value + " " + lname);
            }
            else {
              setUsername(user_response.data.data.field_first_name[0].value);
            }
          }

          if (user_response.data.data.field_first_name) {
            let name = user_response.data.data.field_first_name[0] ? user_response.data.data.field_first_name[0].value : "";
            setFirstName(name);
          }

          if (user_response.data.data.field_last_name) {
            let lastName = user_response.data.data.field_last_name[0] ? user_response.data.data.field_last_name[0].value : "";
            setLastName(lastName);
          }

          if (user_response.data.data.mail) {
            let email = user_response.data.data.mail[0] ? user_response.data.data.mail[0].value : "";
            setEmail(email);
          }

          // if (user_response.data.data.field_d_o_b) {
          //   let dob = user_response.data.data.field_d_o_b[0] ? user_response.data.data.field_d_o_b[0].value : "";
          //   setDob(dob);
          // }

          if (user_response.data.data.field_contact_number) {
            let contact = user_response.data.data.field_contact_number[0] ? user_response.data.data.field_contact_number[0].value : "";
            setContactNumber(contact);
          }

          if (user_response.data.data.head_office_team_profiles.length > 0) {
            let profileId = user_response.data.data.head_office_team_profiles[0].target_id;
            setProfileId(profileId);
            let profileData = await getUserProfileData(profileId);
            if (profileData.status == 200) {
              if(profileData?.data?.data?.field_please_provide_details_bel && profileData?.data?.data?.field_please_provide_details_bel.length > 0){
                const valuesArray = profileData?.data?.data?.field_please_provide_details_bel.map(item => item.value);
                setMedicalConditions(valuesArray)
              }
              if (profileData.data.data.field_profile_picture !== undefined && profileData.data.data.field_profile_picture !== "") {
                let profilePicture = profileData.data.data.field_profile_picture[0] ? profileData.data.data.field_profile_picture[0].url : ""
                setUserPicture(profilePicture)
              }
              if (profileData.data.data.field_commencement_date !== undefined && profileData.data.data.field_commencement_date !== "") {
                let date = profileData.data.data.field_commencement_date[0] ? profileData.data.data.field_commencement_date[0].value : ""
                setCommencementDate(date)
              }
              if (profileData.data.data.field_head_ceased_date !== undefined && profileData.data.data.field_head_ceased_date !== "") {
                let date = profileData.data.data.field_head_ceased_date[0] ? profileData.data.data.field_head_ceased_date[0].value : ""
                setCeasedDate(date)
              }
              if (profileData.data.data.field_business_role !== undefined && profileData.data.data.field_business_role !== "") {
                let _businessRole = profileData.data.data.field_business_role[0] ? profileData.data.data.field_business_role[0].value : ""

                if (_businessRole.includes('<p>')) {
                  setBusinessRole($(_businessRole).text());
                } else {
                  setBusinessRole(_businessRole);
                }
              }

              if (profileData.data.data.field_home_number !== undefined && profileData.data.data.field_home_number !== "") {
                let _home = profileData.data.data.field_home_number[0] ? profileData.data.data.field_home_number[0].value : ""
                setHomeNumber(_home)
              }

              if (profileData.data.data.field_suburb_name !== undefined && profileData.data.data.field_suburb_name !== "") {
                let _suburb = profileData.data.data.field_suburb_name[0] ? profileData.data.data.field_suburb_name[0].value : ""
                setSuburbName(_suburb)
              }
              if (profileData.data.data.field_basic_postcode !== undefined && profileData.data.data.field_basic_postcode !== "") {
                let _postcode = profileData.data.data.field_basic_postcode[0] ? profileData.data.data.field_basic_postcode[0].value : ""
                setPostCode(_postcode)
              }
              if (profileData.data.data.field_street_name !== undefined && profileData.data.data.field_street_name !== "") {
                let _streetName = profileData.data.data.field_street_name[0] ? profileData.data.data.field_street_name[0].value : ""

                if (_streetName.includes('<p>')) {
                  setStreet($(_streetName).text());
                } else {
                  setStreet(_streetName);
                }
              }
              if (profileData.data.data.field_street_number_head !== undefined && profileData.data.data.field_street_number_head !== "") {
                let _streetNumber = profileData.data.data.field_street_number_head[0] ? profileData.data.data.field_street_number_head[0].value : ""
                setStreetNumber(_streetNumber)
              }
              if (profileData.data.data.field_unit_number_head !== undefined && profileData.data.data.field_unit_number_head !== "") {
                let _unitNumber = profileData.data.data.field_unit_number_head[0] ? profileData.data.data.field_unit_number_head[0].value : ""
                setUnitNumber(_unitNumber)
              }

              if (profileData.data.data.field_d_o_b !== undefined && profileData.data.data.field_d_o_b !== "") {
                let date = profileData.data.data.field_d_o_b[0] ? profileData.data.data.field_d_o_b[0].value : ''
                setProfileDob(date)
              }

              if (profileData.data.data.field_first_aid_course !== undefined && profileData.data.data.field_first_aid_course !== "") {
                let first_aid_course = profileData.data.data.field_first_aid_course[0] ? profileData.data.data.field_first_aid_course[0].value : ''
                setFirstAidCourse(first_aid_course);
              }

              if (profileData.data.data.field_cpr_course !== undefined && profileData.data.data.field_cpr_course !== "") {
                let cpr_course = profileData.data.data.field_cpr_course[0] ? profileData.data.data.field_cpr_course[0].value : ''
                setCprCourse(cpr_course);
              }

              if (profileData.data.data.field_blue_card !== undefined && profileData.data.data.field_blue_card !== "") {
                let blue_card = profileData.data.data.field_blue_card[0] ? profileData.data.data.field_blue_card[0].value : ''
                setBlueCard(blue_card);
              }

              if (profileData.data.data.field_signed_date !== undefined && profileData.data.data.field_signed_date !== "") {
                let signed_date = profileData.data.data.field_signed_date[0] ? profileData.data.data.field_signed_date[0].value : ''
                setBlueCardSignedDate(signed_date);
              }

              if (profileData.data.data.field_first_aid_course_reminder !== undefined && profileData.data.data.field_first_aid_course_reminder !== "") {
                let reminder = profileData.data.data.field_first_aid_course_reminder[0] ? profileData.data.data.field_first_aid_course_reminder[0].value : ''
                setFirstAidCourseReminder(reminder);
              }

              if (profileData.data.data.field_cpr_course_reminder !== undefined && profileData.data.data.field_cpr_course_reminder !== "") {
                let reminder = profileData.data.data.field_cpr_course_reminder[0] ? profileData.data.data.field_cpr_course_reminder[0].value : ''
                setCrpCourseReminder(reminder);
              }

              if (profileData.data.data.field_first_aid_completion_date !== undefined && profileData.data.data.field_first_aid_completion_date !== "") {
                let first_aid_completion_date = profileData.data.data.field_first_aid_completion_date[0] ? profileData.data.data.field_first_aid_completion_date[0].value : ''
                setFirstAidCompletionDate(first_aid_completion_date);
              }

              if (profileData.data.data.field_cpr_course_completion_date !== undefined && profileData.data.data.field_cpr_course_completion_date !== "") {
                let cpr_course_completion_date = profileData.data.data.field_cpr_course_completion_date[0] ? profileData.data.data.field_cpr_course_completion_date[0].value : ''
                setCprCourseCompletionDate(cpr_course_completion_date);
              }

              if (profileData.data.data.field_blue_card_expiry_date !== undefined && profileData.data.data.field_blue_card_expiry_date !== "") {
                let blue_expiry_date = profileData.data.data.field_blue_card_expiry_date[0] ? profileData.data.data.field_blue_card_expiry_date[0].value : ''
                setBlueCardCompletionDate(blue_expiry_date);
              }
              if (profileData.data.data.field_blue_card_frequency !== undefined && profileData.data.data.field_blue_card_frequency !== "") {
                let blue_expiry_reminder = profileData.data.data.field_blue_card_frequency[0] ? profileData.data.data.field_blue_card_frequency[0].value : ''
                setBlueCardReminder(blue_expiry_reminder);
              }

              if (profileData.data.data.field_first_expiry_date !== undefined && profileData.data.data.field_first_expiry_date !== "") {
                let first_aid_expiry = profileData.data.data.field_first_expiry_date[0] ? profileData.data.data.field_first_expiry_date[0].value : ''
                setFirstAidExpiry(first_aid_expiry);
              }
              if (profileData.data.data.field_cpr_course_expiry_date !== undefined && profileData.data.data.field_cpr_course_expiry_date !== "") {
                let cpr_expiry = profileData.data.data.field_cpr_course_expiry_date[0] ? profileData.data.data.field_cpr_course_expiry_date[0].value : ''
                setCprExpiry(cpr_expiry);
              }

              // Certificates Data of Courses
              if (profileData.data.data.field_first_aid_certificate !== undefined && profileData.data.data.field_first_aid_certificate.length > 0) {
                let id = profileData.data.data.field_first_aid_certificate[0] ? profileData.data.data.field_first_aid_certificate[0].target_id : '';
                let value = profileData.data.data.field_first_aid_certificate[0] ? (profileData.data.data.field_first_aid_certificate[0].url).slice(66) : '';
                let url = profileData.data.data.field_first_aid_certificate[0] ? (profileData.data.data.field_first_aid_certificate[0].url) : '';
                setFirstAidCertificate([{ value: value, id: id, url: url }])
              }
              if (profileData.data.data.field_cpr_course_certificate !== undefined && profileData.data.data.field_cpr_course_certificate.length > 0) {
                let id = profileData.data.data.field_cpr_course_certificate[0] ? profileData.data.data.field_cpr_course_certificate[0].target_id : '';
                let value = profileData.data.data.field_cpr_course_certificate[0] ? (profileData.data.data.field_cpr_course_certificate[0].url).slice(66) : '';
                let url = profileData.data.data.field_cpr_course_certificate[0] ? (profileData.data.data.field_cpr_course_certificate[0].url) : '';
                setCprCertificate([{ value: value, id: id, url: url }])
              }

              // Multi Qualification Data Getting
              if (profileData.data.data.field_qualification_head !== undefined && profileData.data.data.field_qualification_head !== "" && profileData.data.data.field_issue_date_info !== undefined) {
                let qualification_data = [];

                for (let i = 0; i < profileData.data.data.field_qualification_head.length; i++) {
                  const qualification = profileData.data.data.field_qualification_head[i] ? profileData.data.data.field_qualification_head[i].value : ""
                  const issue_date = profileData.data.data.field_issue_date_info[i] ? profileData.data.data.field_issue_date_info[i].value : ''
                  qualification_data.push({
                    qualification_detail: qualification,
                    issue_date: issue_date,
                    on_calender: false
                  })

                }
                setQualificationDetail(qualification_data)
                setQualificationCount(profileData.data.data.field_qualification_head.length)
              }


            }
          }
        }
      }
      else {
        setAjaxLoader(false);
      }
    }

    getAllInitialData();

    function validateForm() {
      var commencementValidate = document.getElementById("commencement");
      if (commencementValidate !== null) {
        commencementValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Commencement Date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var dobValidate = document.getElementById("dob");
      if (dobValidate !== null) {
        dobValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("DOB can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var firstAidCourseValidate = document.getElementById("firstAidCourse");
      if (firstAidCourseValidate !== null) {
        firstAidCourseValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("First Aid course code can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var crpCourseCodeValidate = document.getElementById("cprCourse");
      if (crpCourseCodeValidate !== null) {
        crpCourseCodeValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("CRP course code can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var firstAidCourseExpiryDateValidate = document.getElementById("firstAidCompletionDate");
      if (firstAidCourseExpiryDateValidate !== null) {
        firstAidCourseExpiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("FirstAid Course Expiry Date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var crpCourseExpiryDateValidate = document.getElementById("cprCourseCompletionDate");
      if (crpCourseExpiryDateValidate !== null) {
        crpCourseExpiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("CRP Course Expiry Date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var blueCardNumberValidate = document.getElementById("blueCardNumber");
      if (blueCardNumberValidate !== null) {
        blueCardNumberValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Bluecard number can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

      var expiryDateValidate = document.getElementById("blueCardComletionDate");
      if (expiryDateValidate !== null) {
        expiryDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Bluecard completion date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }


      var sightedDateValidate = document.getElementById("sightedDate");
      if (sightedDateValidate !== null) {
        sightedDateValidate.oninvalid = function (e) {
          if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            e.target.setCustomValidity("Sighted date can't be empty");
          }
          else {
            e.target.setCustomValidity("");
          }
        };
      }

    }

    validateForm()

  }, [uid])
  const openDocument = (data) => {

    if (!(data === 'Not Available')) {
      window.open(`${baseUrl}${data}`, "_blank")
    }
  }

  const commencementCalendar = () => {
    if (commenceCalendar === true) {
      setCommenceCalendar(false)
    }
    else {
      setCommenceCalendar(true)
    }
  }

  const commencementCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCommencementDate(fullYear)
    setCommenceCalendar(false)
  }

  //ceased calendar
  const ceasedCalendar = () => {
    if (ceaseCalendar === true) {
      setCeasecalendar(false);
    }
    else {
      setCeasecalendar(true);
    }

  };
  //ceased calendar
  const ceasedCalendarHandler = async (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setCeasedDate(fullYear)
    setCeasecalendar(false)
  }

  const profileDobCalendar = () => {
    if (profileDobCal === true) {
      setProfileDobCal(false)
    }
    else {
      setProfileDobCal(true)
    }
  }

  const profileDobCalendarHandler = (newDate) => {
    let requiredDate = moment(newDate).format('YYYY-MM-DD')
    setProfileDob(requiredDate)
    setProfileDobCal(false)
  }

  const blueCardSignedCalendar = () => {
    if (blueCardSignedCal === true) {
      setBlueCardSignedCal(false)
    }
    else {
      setBlueCardSignedCal(true)
    }
  }

  const blueCardCompletionCalendar = () => {
    if (blueCardCompletionCal === true) {
      setBlueCardCompletionCal(false)
    }
    else {
      setBlueCardCompletionCal(true)
    }
  }

  const blueCardSignedDateHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setBlueCardSignedDate(fullYear)
    setBlueCardSignedCal(false)
  }

  const blueCardCompletionDateHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')
    setBlueCardCompletionDate(fullYear)
    setBlueCardCompletionCal(false)
  }

  const selectReminder = (field, selectedValue) => {

    if (field === "firstAid") {
      setFirstAidCourseReminder(selectedValue)
    }
    if (field === "crp") {
      setCrpCourseReminder(selectedValue)
    }
  }

  const coursesCalendar = (name) => {

    if (name === 'firstaidcompletiondate') {
      if (firstAidCal === true) {
        setfirstAidCal(false);
      }
      else {
        setfirstAidCal(true);
      }

      setSelectedField('firstaidcompletiondate')
    }

    if (name === 'cprcoursecompletiondate') {
      if (cprCourseCal === true) {
        setcprCourseCal(false);
      }
      else {
        setcprCourseCal(true);
      }
      setSelectedField('cprcoursecompletiondate')
    }
  }

  const coursesCalendarHandler = (newDate) => {
    let fullYear = moment(newDate).format('YYYY-MM-DD')

    if (selectedField === 'firstaidcompletiondate') {
      setFirstAidCompletionDate(fullYear)
      setfirstAidCal(false)
    }

    if (selectedField === 'cprcoursecompletiondate') {
      setCprCourseCompletionDate(fullYear)
      setcprCourseCal(false)
    }
  }

  const showHideCalender = (field) => {
    if (field === "firstAidExpiry") {
      const result = !firstAidExpiryCalendar
      setFirstAidExpiryCalendar(result)
      setDateField('firstAidExpiry')
    }
    if (field === "cprExpiry") {
      const result = !cprExpiryCalendar
      setcprExpiryCalendar(result)
      setDateField('cprExpiry')
    }
  }


  async function saveProfile(e) {
    // e.preventDefault()
    setAjaxLoader(true)

    let newData = []
    if (qualificationIssueDate != '' && qualificationIssueDate != undefined) {
      newData.push({ qualificationIssueDate: qualificationIssueDate })
    }
    if (commencementDate != '' && commencementDate != undefined) {
      newData.push({ commencementDate: commencementDate })
    }
    if (ceasedDate != '' && ceasedDate != undefined) {
      newData.push({ ceasedDate: ceasedDate })
    }

    if (profileDob != '' && profileDob != undefined) {
      newData.push({ profileDob: profileDob })
    }
    if (firstAidCompletionDate != '' && firstAidCompletionDate != undefined) {
      newData.push({ firstAidCompletionDate: firstAidCompletionDate })
    }
    if (cprCourseCompletionDate != '' && cprCourseCompletionDate != undefined) {
      newData.push({ cprCourseCompletionDate: cprCourseCompletionDate })
    } 
    if (firstAidExpiry != '' && firstAidExpiry != undefined) {
      newData.push({ firstAidExpiry: firstAidExpiry })
    }
    if (cprExpiry != '' && cprExpiry != undefined) {
      newData.push({ cprExpiry: cprExpiry })
    }
    if (blueCardSignedDate != '' && blueCardSignedDate != undefined) {
      newData.push({ blueCardSignedDate: blueCardSignedDate })
    }
    if (blueCardCompletionDate != '' && blueCardCompletionDate != undefined) {
      newData.push({ blueCardCompletionDate: blueCardCompletionDate })
    }

    let data = {
      // uid: user_id,
      uid: uid,
      // dob: dob ? moment(dob).format('YYYY-MM-DD') : null,
      first_name: firstName,
      last_name: lastName,
      contactNumber: contactNumber,
      mobile_number: homeNumber,
      // commencementDate: commencementDate ? moment(commencementDate).format('YYYY-MM-DD') : null,
      // ceasedDate: ceasedDate,
      // profileDob: profileDob ? moment(profileDob).format('YYYY-MM-DD') : null,
      // asthmaCourse: asthmaCourse,
      firstAidCourse: firstAidCourse,
      cprCourse: cprCourse,
      firstAidCourseReminder: firstAidCourseReminder,
      crpCourseReminder: crpCourseReminder,
      // asthmaCourseReminder: asthmaCourseReminder,
      // anaphylaxisCourseReminder: anaphylaxisCourseReminder,
      // anaphylaxisCourse: anaphylaxisCourse,
      // firstAidCompletionDate: firstAidCompletionDate ? moment(firstAidCompletionDate).format('YYYY-MM-DD') : null,
      // cprCourseCompletionDate: cprCourseCompletionDate ? moment(cprCourseCompletionDate).format('YYYY-MM-DD') : null,
      // asthCourseCompletionDate: asthCourseCompletionDate ? moment(asthCourseCompletionDate).format('YYYY-MM-DD') : null,
      // anapCourseCompletionDate: anapCourseCompletionDate ? moment(anapCourseCompletionDate).format('YYYY-MM-DD') : null,
      blueCard: blueCard,
      blueCardAdditionalInfo: "",
      // blueCardSignedDate: blueCardSignedDate,
      suburbName: suburbName,
      postCode: postCode,
      street: street,
      streetNumber: streetNumber,
      unitNumber: unitNumber,
      // blueCardCompletionDate: blueCardCompletionDate,
      blueCardReminder: blueCardReminder,
      // firstAidExpiry: firstAidExpiry ? firstAidExpiry : null,
      // cprExpiry: cprExpiry ? cprExpiry : null,
      // asthmaExpiry: asthmaExpiry ? asthmaExpiry : null,
      // anaphylaxisExpiry: anaphylaxisExpiry ? anaphylaxisExpiry : null,
      field_cpr_certificate: cprCertificate[0].id ? cprCertificate[0].id : null,
      field_certificate_document: firstAidCertificate[0].id ? firstAidCertificate[0].id : null,
      businessRole: businessRole,
      field_please_provide_details_bel:medicalConditions

    };
    var newObj = Object.assign({}, ...(newData.map(item => item)));
    const finalData = Object.assign({}, data, newObj);

    let response = await updateHeadOfficeProfile(profileId, finalData);
    if (response.status === 200) {
      setAjaxLoader(false)
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });
    } else {
      setAjaxLoader(false)
      toast.error(response.message, {
        position: 'top-center'
      });
    }
    let responseUser = await updateUserData(uid, data);
    if (responseUser.status == 200) {

    } else {
      setAjaxLoader(false)
      toast.error(responseUser.message, {
        position: 'top-center'
      });
    }
  }
  // Qualification Part 
  const addMoreQualification = () => {
    setQualificationDetail([...qualificationDetail,
    {
      qualification_detail: '',
      issue_date: '',
      on_calender: false
    }])
  }

  const onChangeQualification = (event, index) => {
    let { name, value } = event.target;
    let markers = [...qualificationDetail];
    markers[index] = { ...markers[index], ["qualification_detail"]: value };
    setQualificationDetail(markers)
  }
  const qualificationIssueDateSet = (index) => {
    let markers = [...qualificationDetail];
    markers[index] = { ...markers[index], ["on_calender"]: true };
    setQualificationDetail([...markers])
  }

  const onChangeIssueDateQualification = (value, index, event) => {
    let markers = [...qualificationDetail];
    markers[index] = { ...markers[index], on_calender: false };
    markers[index] = { ...markers[index], ["issue_date"]: moment(event).format('YYYY-MM-DD') };
    setQualificationDetail([...markers])

  }

  const saveQualificaitonProfile = async () => {
    // let uid = window.localStorage.getItem('uid');
    let qualification = [];
    let issue_date = [];
    setAjaxLoader(true);
    for (let i = 0; i < qualificationDetail.length; i++) {
      qualification.push({ "value": qualificationDetail[i].qualification_detail });
      issue_date.push({ "value": qualificationDetail[i].issue_date });
    }


    const configData = {
      "type": "head_office_team",
      "uid": [{ "target_id": uid }],
      "field_qualification_head": qualification,
      "field_issue_date_info": issue_date

    }
    const responseData = await upDateOccupants(profileId, configData);
    if (responseData.status.status === 200) {
      setAjaxLoader(false);
      toast.success('Profile Updated Successfully!!', {
        position: 'top-center'
      });

    } else {
      setAjaxLoader(false);
    }
  }

  const removeQualification = () => {
    qualificationDetail.pop();
    setQualificationIssueDate([...qualificationDetail])
  }

  const onChangeDate = (newDate) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1
    let year = newDate.getFullYear()
    let date = newDate.getDate()
    let fullYear = year + "-" + month + "-" + date

    if (dateField === 'firstAidExpiry') {
      setFirstAidExpiry(fullYear)
      setFirstAidExpiryCalendar(false)
    }
    if (dateField === "cprExpiry") {
      setCprExpiry(fullYear)
      setcprExpiryCalendar(false)
    }
    // if (dateField === "asthmaExpiry") {
    //   setAsthmaExpiry(fullYear)
    //   setAsthmaExpiryCalendar(false)
    // }
    // if (dateField === "anaphylaxisExpiry") {
    //   setAnaphylaxis(fullYear)
    //   setAnaphalyxasisCalendar(false)
    // }

    setDateField("")
  }
  const showHideDropdown = (value) => {
    if (value === "pdMethods") {
      let _show = professionalDevelopmentStates.showMethod;
      if (_show === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showMethod: true,
        });
      }
    }
  };

  //PD
  const openPDModal = () => {

    setIsEditPDL(false)
    setProfessionalDevelopmentStates(initialPDStates);
    setOpenProfessionalDevelopmentModal(true);
  };

  const showHidePDCalender = (value) => {
    if (value === "date") {
      let _calenderDisplay = professionalDevelopmentStates.showDateCalender;
      if (_calenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDateCalender: true,
        });
      }
    } else {
      let _durationCalenderDisplay =
        professionalDevelopmentStates.showDurationCalender;
      if (_durationCalenderDisplay === true) {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: false,
        });
      } else {
        setProfessionalDevelopmentStates({
          ...professionalDevelopmentStates,
          showDurationCalender: true,
        });
      }
    }
  };
  const onChangePDDate = (newDate, field) => {
    let month = newDate.getMonth() < 12 ? newDate.getMonth() + 1 : 1;
    let year = newDate.getFullYear();
    let date = newDate.getDate();
    let fullYear = year + "-" + month + "-" + date;

    if (field === "date") {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: fullYear,
        showDateCalender: false,
      });
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        duration: fullYear,
        showDurationCalender: false,
      });
    }
  };
  const uploadImages = (event, fieldName) => {
    let arrayOfValues = [];
    setProfessionalDevelopmentStates({ ...professionalDevelopmentStates, fid: '' })
    if (event.target.files !== undefined && Object.keys(event.target.files).length > 0 && Object.keys(event.target.files).length <= 5) {
      if (event.target.files[0].type !== "image/jpg" && event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" && event.target.files[0].type !== "application/pdf") {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          // imagesArray = professionalDevelopmentStates.certificate
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = function (events) {
              imageObject["path"] = events.target.result;
            };
            imagesArray.push(imageObject);
            setUploadCertificate([imagesArray[0]])
            setProfessionalDevelopmentStates({
              ...professionalDevelopmentStates,
              certificate: [...imagesArray],
            });

            const responseAPI = await uploadDocument(imagesArray[0].name, imagesArray[0].path)
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };

  const removeSpecificImage = (index) => {
    let imagesArray = professionalDevelopmentStates.certificate;
    imagesArray.splice(index, 1);
    setProfessionalDevelopmentStates({
      ...professionalDevelopmentStates,
      certificate: [...imagesArray],
    });
  };
  const validateFieldsPd = () => {
    let formIsValid = true;

    if (professionalDevelopmentStates.method == "") {
      setError((error) => ({
        ...error,
        methodError: "Please enter your Method",
      }));
      formIsValid = false;
    }
    return formIsValid;
  };
  // Upload different Types of Certificate :
  const uploadCertificateForCourses = (event, fieldName) => {
    // let arrayOfValues = [];
    if (
      event.target.files !== undefined &&
      Object.keys(event.target.files).length > 0 &&
      Object.keys(event.target.files).length <= 5
    ) {
      if (
        event.target.files[0].type !== "image/jpg" &&
        event.target.files[0].type !== "image/jpeg" &&
        event.target.files[0].type !== "image/png" &&
        event.target.files[0].type !== "application/pdf"
      ) {
        toast.error(
          "Invalid Image Type. Image can only be of the following types : jpg, jpeg, png ,pdf",
          {
            position: "top-center",
          }
        );
        return false;
      } else {
        if (event.target.files[0].size > 5000000) {
          toast.error("Image size exceeds the limit of 5MB", {
            position: "top-center",
          });
          return false;
        } else {
          let imagesArray = [];
          Object.values(event.target.files).map(async function (key, index) {
            let imageObject = {
              name: "",
              path: "",
            };
            if (key.name !== undefined) {
              imageObject["name"] = key.name;
            }
            var reader = new FileReader();
            reader.onload = async function (events) {
              imageObject["path"] = events.target.result;
              const responseAPI = await uploadCertificatesAPI(imageObject.name, events.target.result, fieldName)
              imagesArray.push(imageObject);
              if (fieldName === 'first_aid') {
                setFirstAidCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
              if (fieldName === 'cpr_couse') {
                setCprCertificate([{ value: imagesArray[0].name, id: responseAPI.data.data.fid[0].value, url: responseAPI.data.data.uri[0].url }])
              }
            };
            reader.readAsDataURL(key);
          });
        }
      }
    } else {
      toast.error("You can't upload more than 5 images", {
        position: "top-center",
      });
    }
  };


  async function professionaSubmit(e, type) {
    e.preventDefault();
    if (type) {
      setAjaxLoader(true);
      let object;
      if (!(uploadCertificate.length > 0)) {
        object = {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
          "field_task_status": [{ "value": professionalDevelopmentStates.method }],
          "field_course_name": [
            { "value": professionalDevelopmentStates.courseName }
          ],
          "field_organisation": [
            { "value": professionalDevelopmentStates.organisation }
          ],
        }
      }
      else {
        let professionalImage = await uploadImage(uploadCertificate[0].name, uploadCertificate[0].path)

        if (professionalImage.status == 201) {

          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ],
            field_new_upload_certificate: [{ target_id: professionalImage.data.data.fid[0].value }]
          }
        } else {
          object = {
            "type": [{
              "target_id": "professional_development",
              "target_type": "paragraphs_type"
            }],
            "field_date_completed": [{ "value": professionalDevelopmentStates.date }],
            "field_task_status": [{ "value": professionalDevelopmentStates.method }],
            "field_course_name": [
              { "value": professionalDevelopmentStates.courseName }
            ],
            "field_organisation": [
              { "value": professionalDevelopmentStates.organisation }
            ]
          }
        }
      }

      const response = await updateProfessionalDevelopmentLog(editableData.id, object);
      if (response.status === 200) {
        await getProfessionalDevelop();
        toast.success("Professional Development log item updated successfully", {
          position: "top-center",
        });
        setAjaxLoader(false);
        setProfessionalDevelopmentStates(initialPDStates);
        setOpenProfessionalDevelopmentModal(false);
      } else {
        setAjaxLoader(false);
      }
      return;
    } else {
      if (validateFieldsPd()) {

        setAjaxLoader(true);
        let openingHours = professional_time;
        let timeArray = openingHours.split(':')
        let hours = timeArray[0]
        let minutes = timeArray[1]
        let finalOpeningHours = hours * 3600 + minutes * 60

        let object =
        {
          "type": [{
            "target_id": "professional_development",
            "target_type": "paragraphs_type"
          }],
          field_date_completed: [{ value: professionalDevelopmentStates.date }],
          field_task_status: [{ value: professionalDevelopmentStates.method }],
          field_course_name: [
            { value: professionalDevelopmentStates.courseName },
          ],
          field_organisation: [
            { value: professionalDevelopmentStates.organisation },
          ],
          field_select_time: [{ value: finalOpeningHours }]

        };

        if (professionalDevelopmentStates.certificate.length > 0) {
          let _imagesArray = []
          professionalDevelopmentStates.certificate.map(async (data) => {

            let professionalImage = await uploadImage(data.name, data.path)

            if (professionalImage.status == 201) {
              _imagesArray.push({ target_id: professionalImage.data.data.fid[0].value })

              if (_imagesArray.length === professionalDevelopmentStates.certificate.length) {
                Object.assign(object, { "field_new_upload_certificate": _imagesArray })


                let addProfessionalD = await addProfessionalDevelop(object);
                if (addProfessionalD.status == 201) {
                  let responseData = addProfessionalD.data.data

                  let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
                  let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";

                  //if for updateProfessionalProfile
                  if (professionalId !== "" && revisionId !== "") {
                    let user_id = uid

                    let profile_id = await getSpecificUser(user_id)
                    if (profile_id.status == 200) {
                      let objects =
                      {
                        "type": [{
                          "target_id": "professional_development",
                          "target_type": "paragraphs_type"
                        }],
                        "profileId": profile_id.data.data.head_office_team_profiles[0].target_id,
                      };

                      let field_quality_arr = [];
                      if (professionalDevelop.length > 0) {
                        professionalDevelop.map((v) => {
                          field_quality_arr.push({
                            "target_id": v.id,
                            "target_revision_id": v.revision_id
                          })
                        })
                      }

                      field_quality_arr.push({
                        "target_id": professionalId,
                        "target_revision_id": revisionId
                      })

                      let obj = {
                        "type": "head_office_team",
                        "uid": [{
                          "target_id": user_id
                        }],
                        "field_head_professional_developm": field_quality_arr,
                      }

                      let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                      if (updateProfessionalDevelop.status == 200) {
                        await getProfessionalDevelop();
                        toast.success("Professional Development log item added successfully", {
                          position: "top-center",
                        });
                        setAjaxLoader(false);
                        setProfessionalDevelopmentStates(initialPDStates);
                        setOpenProfessionalDevelopmentModal(false);
                      } else {
                        setAjaxLoader(false);
                        toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                      }
                    }
                    else {
                      setAjaxLoader(false);
                      toast.error('something went wrong', { position: "top-center" });
                    }
                  }
                }
                else {
                  setAjaxLoader(false);
                  toast.error(addProfessionalD.message, { position: "top-center" });
                }
              }
              setAjaxLoader(false);
              await getProfessionalDevelop();
            } else {
              setAjaxLoader(false);
              toast.error(professionalImage.message, { position: "top-center" });
            }
          });
        }
        else {

          let addProfessionalD = await addProfessionalDevelop(object);
          if (addProfessionalD.status == 201) {
            let responseData = addProfessionalD.data.data

            let professionalId = responseData.id.length > 0 ? responseData.id[0].value : "";
            let revisionId = responseData.revision_id.length > 0 ? responseData.revision_id[0].value : "";

            //if for updateProfessionalProfile
            if (professionalId !== "" && revisionId !== "") {
              let user_id = uid

              let profile_id = await getSpecificUser(user_id)
              if (profile_id.status == 200) {
                let objects =
                {
                  "type": [{
                    "target_id": "professional_development",
                    "target_type": "paragraphs_type"
                  }],
                  "profileId": profile_id.data.data.head_office_team_profiles[0].target_id,
                };
                let field_quality_arr = [];

                if (professionalDevelop.length > 0) {
                  professionalDevelop.map((v) => {
                    field_quality_arr.push({
                      "target_id": v.id,
                      "target_revision_id": v.revision_id
                    })
                  })
                }

                field_quality_arr.push({
                  "target_id": professionalId,
                  "target_revision_id": revisionId
                })

                let obj = {
                  "type": "head_office_team",
                  "uid": [{
                    "target_id": user_id
                  }],
                  "field_head_professional_developm": field_quality_arr,
                }


                let updateProfessionalDevelop = await updateProfessionalProfile(obj, objects.profileId);
                if (updateProfessionalDevelop.status == 200) {
                  toast.success("Professional Development log item added successfully", {
                    position: "top-center",
                  });
                  setAjaxLoader(false);
                  setOpenProfessionalDevelopmentModal(false)
                  await getProfessionalDevelop();
                } else {
                  setAjaxLoader(false);
                  toast.error(updateProfessionalDevelop.message, { position: "top-center" });
                }

              }
              else {
                setAjaxLoader(false);
                toast.error('something went wrong', { position: "top-center" });
              }
            }
          }
          else {
            setAjaxLoader(false);
            toast.error(addProfessionalD.message, { position: "top-center" });
          }
        }
      }
    }

  }
  //EDIT PROFESSIONAL DEVELOPMENT

  async function editProfessional(e) {
    setEditableData(e);
    const response = await getOccupant(e.id);
    setIsEditPDL(true);
    const fidCheck = response.data.data.field_new_upload_certificate && response.data.data.field_new_upload_certificate.length > 0 && response.data.data.field_new_upload_certificate[0].target_id;

    setOpenProfessionalDevelopmentModal(true)
    set_professional_time(e.field_select_time)

    let object = { "name": (e.field_new_upload_certificate).slice(29, 47), "path": `${baseUrl}${e.field_new_upload_certificate}` }
    if (e.field_new_upload_certificate) {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [object],
        fid: fidCheck ? fidCheck : ''
      })
    } else {
      setProfessionalDevelopmentStates({
        ...professionalDevelopmentStates,
        date: e.field_date_completed, method: e.field_task_status,
        courseName: decode(e.field_course_name),
        organisation: decode(e.field_organisation),
        certificate: [],
        fid: ''
      })
    }
  }
  const getProfessionalDevelop = async () => {
    let response = await getProfessional(uid);
    if (response.status == 200) {
      if (response.data.data !== undefined && response.data.data.length > 0) {
        setProfessionalDevelop(response.data.data);
      } else {
        setProfessionalDevelop([]);
        setProfessionalDevelop(response.data.data);
      }
    } else {
      toast.error(response.message, { position: "top-center" });
    }
  };
  const deleteProfessioanlDevelop = async (id) => {
    setAjaxLoader(true);

    let response = await deleteProfessioanlDevelopLogs(id);
    if (response.status === 204) {
      setAjaxLoader(false);

      toast.success("Professional development Log item deleted successfully", {
        position: "top-center",
      });
      await getProfessionalDevelop();
    } else {
      setAjaxLoader(false);
      toast.error(response.message, { position: "top-center" });
    }
  };


  const handleContactChange = (index, type,event) => {    
    const updatedContacts = [...contacts];
    if(type == 'name'){
      updatedContacts[index].field_title = event.target.value;
    }else{
      updatedContacts[index].field_phone = event.target.value.replace(/\D/g, "");
    }
    setContacts(updatedContacts);
  };

  const handleMedicalChange = (index, event) => {
    const updatedConditions = [...medicalConditions];
    updatedConditions[index] = event.target.value;
    setMedicalConditions(updatedConditions);
  };

  const addMedicalCondition = () => {
    setMedicalConditions([...medicalConditions, ""]);
  };
  const removeUrlField=(index)=>{
      let urls = medicalConditions
      urls.splice(index,1)
      setMedicalConditions([...urls])
    }

    const addEmergencyContactsApi = async () => {
      setAjaxLoader(true)
      let bodyData = [];
      
      contacts.forEach(data => {
        let bodyItem = {
          title: data.field_title,
          phone: data.field_phone
        };
        if (data.id) {
          bodyItem.target_id = data.id;
        }
        bodyData.push(bodyItem);
      });
    
      try {
        const response = await addEmergencyContacts(uid,profileId, bodyData);
        if (response.status === 200) {
          setAjaxLoader(false)
          toast.success('Profile Updated Successfully!!', {
            position: 'top-center'
          });
        } else {
          setAjaxLoader(false)
          toast.error(response.message, {
            position: 'top-center'
          });
        }
      } catch (error) {
        console.error('Error adding emergency contacts:', error);
      }
    };

  return (
    <>
      <ToastContainer />
      <LoaderComponent displayLoader={ajaxLoader} />
      <HeadOfficeMainHeader />
      <div className="main-wrapper mtb-70">
        <div className="container">
          <div className="dashboard-wrap">
            {/* <form onSubmit={saveProfile}> */}
            <div className="common-header-row common-header-with-large-text profe-logs">
              {/* <h4>Edit Profile</h4> */}
              <div className="block-right">
                <div className="risk-assi-btns">
                  <button className="mdc-button mdc-button--raised"
                    onClick={openPDModal}
                  >
                    <span class="mdc-button__label">
                      Add Professional Development Log
                    </span>
                  </button>

                  <Link onClick={() => history.goBack()} className="dropdown-list"><span className="material-icons">arrow_back</span> Go Back</Link>
                  {/* <button className="mdc-button mdc-button--raised green-btn">
                                        <span className="mdc-button__label">Save</span>
                                    </button> */}
                </div>
              </div>
            </div>
            <div className="user">
              <div className="user-profile-block text-center educator-view-profile-page">
                <div className="media">
                  <img width="100" height="100" src={userPicture ? userPicture : DummyImage} />
                </div>
                {username ? <h1 className="email">{username}</h1> : null}

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="user-pages-accordion-wrap">
                  <div id="accordion" className="accordion-container">
                    <h4 className="accordion-title js-accordion-title">Basic Information</h4>
                    <div className="accordion-content">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="20" type="text" onChange={(e) => setFirstName(e.target.value)} value={firstName}
                              className={firstName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">First Name*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>

                        <div className="col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="20" type="text" onChange={(e) => setLastName(e.target.value)} value={lastName}
                              className={lastName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Last Name*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>

                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input readOnly maxlength="25" type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={email ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Email*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => profileDobCalendar()} >
                              <input value={profileDob ? moment(profileDob).format('DD-MM-YYYY') : profileDob} type="text" className={profileDob ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="dob" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">D.O.B*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                                <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            <div>
                              {
                                profileDobCal === true ? <Calendar onChange={profileDobCalendarHandler} /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="15" value={homeNumber} onChange={(e) => setHomeNumber(e.target.value.replace(/\D/g, ""))} type="text" className={homeNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Mobile Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                            <span className="material-icons">phone</span>
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="25" value={suburbName} onChange={(e) => setSuburbName(e.target.value)} type="text" className={suburbName ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Suburb Name*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="25" value={postCode} onChange={(e) => setPostCode(e.target.value)} type="text" className={postCode ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Post Code*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="50" value={unitNumber} onChange={(e) => { setUnitNumber(e.target.value) }} type="text" className={unitNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Unit Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="50" value={streetNumber} onChange={(e) => { setStreetNumber(e.target.value) }} type="text" className={streetNumber ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Street Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="50" value={street} onChange={(e) => { setStreet(e.target.value) }} type="text" className={street ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Street Name*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>
                    <h4 className="accordion-title js-accordion-title">Business Information</h4>
                    <div className="accordion-content">
                      <div className="row">
                        <div className="col-lg-4 col-md-4">
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => commencementCalendar()}>
                              <input value={commencementDate ? moment(commencementDate).format('DD-MM-YYYY') : commencementDate} type="text" className={commencementDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="commencement" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Commencement Date*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                                <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            <div>
                              {
                                commenceCalendar === true ? <Calendar onChange={commencementCalendarHandler} /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => ceasedCalendar()} >
                              <input value={ceasedDate ? moment(ceasedDate).format('DD-MM-YYYY') : ceasedDate} type="text" className={ceasedDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                id="ceasedDate" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Ceased Date*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                                <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            <div>
                              {
                                ceaseCalendar === true ? <Calendar onChange={ceasedCalendarHandler} /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input value={businessRole} onChange={(e) => setBusinessRole(e.target.value)} type="text" className={businessRole ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Role*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>

                    <h4 className="accordion-title js-accordion-title" >
                      Qualification
                    </h4>
                    <div className="accordion-content">
                      {qualificationDetail && qualificationDetail.length > 0 ? qualificationDetail.map((value, index) => {
                        return (
                          <div className="row">
                            <div className="col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input type="text" onChange={(e) => onChangeQualification(e, index)} value={value.qualification_detail} className={value.qualification_detail ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Qualification*</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => qualificationIssueDateSet(index)} >
                                  <input value={value.issue_date ? moment(value.issue_date).format('DD-MM-YYYY') : value.issue_date} type="text" className={value.issue_date ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="qualification-calendar" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Issue Date*</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                    <span className="material-icons">calendar_today</span>
                                  </span>
                                </label>
                                <div>
                                  {
                                    value.on_calender === true ? <Calendar onChange={(e) => onChangeIssueDateQualification(value, index, e)} /> : null
                                  }
                                </div>
                              </div>
                            </div>
                            {index < qualificationCount ? null : <span className="material-icons align-at-end" style={{ cursor: "pointer" }} onClick={removeQualification}>clear</span>}
                          </div>


                        )
                      }) : null}
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveQualificaitonProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                      <button className="mdc-button mdc-button--raised green-btn ml-5" type="submit" value='submit' onClick={addMoreQualification}  >
                        <span className="mdc-button__label">Add Qualification</span>
                      </button>

                    </div>

                    <h4 className="accordion-title js-accordion-title">First Aid Certifications</h4>
                    <div className="accordion-content">
                      <div className="row">
                        <div className="col-md-6">
                          <label className="text-left">First Aid Course</label>
                          <div className="row custom-row">
                            <div className="col-lg-4 col-md-6 custom-col">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input value={firstAidCourse} onChange={(e) => { setFirstAidCourse(e.target.value); e.target.setCustomValidity(""); }} type="text" className={firstAidCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" maxlength="20" id="firstAidCourse" onBlur={(e) => e.target.setCustomValidity("")} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('firstaidcompletiondate')}>
                                  <input value={firstAidCompletionDate ? moment(firstAidCompletionDate).format('DD-MM-YYYY') : firstAidCompletionDate} type="text" className={firstAidCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="firstAidCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                    <span className="material-icons">calendar_today</span>
                                  </span>
                                </label>
                                <div>
                                  {
                                    firstAidCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="select-field">
                                <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                  <label className="reminder-field-style">Set Reminder*</label>
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{firstAidCourseReminder ? firstAidCourseReminder : "Set Reminder"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">arrow_drop_down</span>
                                </label>
                                <ul className="dropdown">
                                  <>
                                    {
                                      reminder.map((item, index) =>
                                        <li onClick={() => selectReminder("firstAid", item)} key={index}>{item}</li>
                                      )
                                    }
                                  </>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={firstAidExpiry ? moment(firstAidExpiry).format('DD-MM-YYYY') : firstAidExpiry} onkeypress="return false;" required autoComplete="off" type="text" className={firstAidExpiry ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    onClick={() => showHideCalender("firstAidExpiry")} id="firstAidCourseExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">calendar_today</span>
                                </label>
                                <div>
                                  {
                                    firstAidExpiryCalendar === true ? <Calendar onChange={onChangeDate} /> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'first_aid')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{firstAidCertificate[0].value ? firstAidCertificate[0].value : "Upload Certificate"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">image</span>
                              </label>
                              <>
                                {
                                  firstAidCertificate.length > 0 && firstAidCertificate[0].value != '' ?
                                    firstAidCertificate.map((items) => {
                                      let imageUrl1 = items.url
                                      if (!items.url.includes(baseUrl)) {
                                        imageUrl1 = baseUrl + items.url
                                      }
                                      let splittedArray = items.value.split(".")
                                      let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                        splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                          : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                            : imageUrl1
                                      return (

                                        <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                          <figure>
                                            <a href={imageUrl1} target="_blank"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} /></a>
                                          </figure>
                                        </li>
                                      )
                                    })
                                    : null
                                }
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="text-left">CPR Course</label>
                          <div className="row custom-row">
                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={cprCourse} onChange={(e) => { setCprCourse(e.target.value); e.target.setCustomValidity("") }} type="text" className={cprCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                    aria-labelledby="my-label-id" id="cprCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('cprcoursecompletiondate')} >
                                  <input value={cprCourseCompletionDate ? moment(cprCourseCompletionDate).format('DD-MM-YYYY') : cprCourseCompletionDate} type="text" className={cprCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="cprCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                    <span className="material-icons">calendar_today</span>
                                  </span>
                                </label>
                                <div>
                                  {
                                    cprCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="select-field">
                                <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                  <label className="reminder-field-style">Set Reminder*</label>
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{crpCourseReminder ? crpCourseReminder : "Set Reminder"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">arrow_drop_down</span>
                                </label>
                                <ul className="dropdown">
                                  <>
                                    {
                                      reminder.map((item, index) =>
                                        <li onClick={() => selectReminder("crp", item)} key={index}>{item}</li>
                                      )
                                    }
                                  </>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={cprExpiry ? moment(cprExpiry).format('DD-MM-YYYY') : cprExpiry} onkeypress="return false;" required autoComplete="off" type="text" className={cprExpiry ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    onClick={() => showHideCalender("cprExpiry")} id="firstAidCourseExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">calendar_today</span>
                                </label>
                                <div>
                                  {
                                    cprExpiryCalendar === true ? <Calendar onChange={onChangeDate} /> : null
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'cpr_couse')} accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{cprCertificate[0].value ? cprCertificate[0].value : "Upload Certificate"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">image</span>
                              </label>
                              <>
                                {
                                  cprCertificate.length > 0 && cprCertificate[0].url != '' ?
                                    cprCertificate.map((items) => {
                                      let imageUrl2 = items.url
                                      if (!items.url.includes(baseUrl)) {
                                        imageUrl2 = baseUrl + items.url
                                      }
                                      let splittedArray = items.value.split(".")
                                      let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                        splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                          : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                            : imageUrl2
                                      return (

                                        <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                          <figure>
                                            <a href={imageUrl2} target="_blank"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} /></a>
                                          </figure>
                                        </li>
                                      )
                                    })
                                    : null
                                }
                              </>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-6">
                          <label className="text-left">Asthma Course</label>
                          <div className="row custom-row">
                            <div className="col-lg-4 col-md-6 custom-col">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input value={asthmaCourse} type="text" onChange={(e) => { setAsthmaCourse(e.target.value); e.target.setCustomValidity("") }} className={asthmaCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" id="asthmaCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('asthcoursecompletiondate')}>
                                  <input value={asthCourseCompletionDate ? moment(asthCourseCompletionDate).format('DD-MM-YYYY') : asthCourseCompletionDate} type="text" className={asthCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="asthCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                    <span className="material-icons">calendar_today</span>
                                  </span>
                                </label>
                                <div>
                                  {
                                    asthCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="select-field">
                                <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                  <label className="reminder-field-style">Set Reminder*</label>
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{asthmaCourseReminder ? asthmaCourseReminder : "Set Reminder"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">arrow_drop_down</span>
                                </label>
                                <ul className="dropdown">
                                  <>
                                    {
                                      reminder.map((item, index) =>
                                        <li onClick={() => selectReminder("asthma", item)} key={index}>{item}</li>
                                      )
                                    }
                                  </>
                                </ul>
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={asthmaExpiry ? moment(asthmaExpiry).format('DD-MM-YYYY') : asthmaExpiry} onkeypress="return false;" required autoComplete="off" type="text" className={asthmaExpiry ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    onClick={() => showHideCalender("asthmaExpiry")} id="firstAidCourseExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">calendar_today</span>
                                </label>
                                <div>
                                  {
                                    asthmaExpiryCalendar === true ? <Calendar onChange={onChangeDate} /> : null
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'asthma_course')}
                                  accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{asthmaCertificate[0].value ? asthmaCertificate[0].value : "Upload Certificate"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">image</span>
                              </label>
                              <>
                                {
                                  asthmaCertificate.length > 0 && asthmaCertificate[0].url != '' ?
                                    asthmaCertificate.map((items) => {
                                      let imageUrl3 = items.url
                                      if (!items.url.includes(baseUrl)) {
                                        imageUrl3 = baseUrl + items.url
                                      }
                                      let splittedArray = items.value.split(".")
                                      let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                        splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                          : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                            : imageUrl3
                                      return (

                                        <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                          <figure>
                                            <a href={imageUrl3} target="_blank"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} /></a>
                                          </figure>
                                        </li>
                                      )
                                    })
                                    : null
                                }
                              </>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label className="text-left">Anaphylaxis Course</label>
                          <div className="row custom-row">
                            <div className="col-lg-4 col-md-6 custom-col">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input value={anaphylaxisCourse} onChange={(e) => { setAnaphylaxisCourse(e.target.value); e.target.setCustomValidity(""); }} type="text" className={anaphylaxisCourse ? "mdc-text-field__input active" : "mdc-text-field__input"}
                                  aria-labelledby="my-label-id" id="anaphylaxisCourse" onBlur={(e) => e.target.setCustomValidity("")} maxlength="20" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Course Code</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined" onClick={() => coursesCalendar('anapcoursecompletiondate')}>
                                  <input value={anapCourseCompletionDate ? moment(anapCourseCompletionDate).format('DD-MM-YYYY') : anapCourseCompletionDate} type="text" className={anapCourseCompletionDate ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    id="anapCourseCompletionDate" onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Completion Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                    <span className="material-icons">calendar_today</span>
                                  </span>
                                </label>
                                <div>
                                  {
                                    anapCourseCal === true ? <Calendar onChange={coursesCalendarHandler} /> : null
                                  }
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-4 col-md-6 custom-col">
                              <div className="select-field">
                                <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                                  <label className="reminder-field-style">Set Reminder*</label>
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">{anaphylaxisCourseReminder ? anaphylaxisCourseReminder : "Set Reminder"}</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">arrow_drop_down</span>
                                </label>
                                <ul className="dropdown">
                                  <>
                                    {
                                      reminder.map((item, index) =>
                                        <li onClick={() => selectReminder("anaphylaxis", item)} key={index}>{item}</li>
                                      )
                                    }
                                  </>
                                </ul>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6 custom-col">
                              <div className="calendar-wrap">
                                <label className="mdc-text-field mdc-text-field--outlined">
                                  <input value={anaphylaxisExpiry ? moment(anaphylaxisExpiry).format('DD-MM-YYYY') : anaphylaxisExpiry} onkeypress="return false;" required autoComplete="off" type="text" className={anaphylaxisExpiry ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                    onClick={() => showHideCalender("anaphylaxisExpiry")} id="firstAidCourseExpiryDate" onBlur={(e) => e.target.setCustomValidity("")} />
                                  <span className="mdc-notched-outline">
                                    <span className="mdc-notched-outline__leading"></span>
                                    <span className="mdc-notched-outline__notch">
                                      <span className="mdc-floating-label" id="my-label-id">Expiry Date</span>
                                    </span>
                                    <span className="mdc-notched-outline__trailing"></span>
                                  </span>
                                  <span className="material-icons">calendar_today</span>
                                </label>
                                <div>
                                  {
                                    anaphylaxisExpiryCalendar === true ? <Calendar onChange={onChangeDate} /> : null
                                  }
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input type="file" name="file" onChange={(e) => uploadCertificateForCourses(e, 'anaphylaxis_course')}
                                  accept="image/jpg,image/jpeg,image/png,.pdf" className="mdc-text-field__input attach-file" aria-labelledby="my-label-id" />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">{anaphylaxisCertificate[0].value ? anaphylaxisCertificate[0].value : "Upload Certificate"}</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                                <span className="material-icons">image</span>
                              </label>
                              <>
                                {
                                  anaphylaxisCertificate.length > 0 && anaphylaxisCertificate[0].url != '' ?
                                    anaphylaxisCertificate.map((items) => {
                                      let imageUrl4 = items.url
                                      if (!items.url.includes(baseUrl)) {
                                        imageUrl4 = baseUrl + items.url
                                      }
                                      let splittedArray = items.value.split(".")
                                      let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                                        splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                          : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                            : imageUrl4
                                      return (

                                        <li style={{ cursor: 'pointer', marginBottom: 40, listStyle: 'none' }}  >
                                          <figure>
                                            <a href={imageUrl4} target="_blank"><img style={{ maxHeight: '200px' }} src={uploadedImageIcon} /></a>
                                          </figure>
                                        </li>
                                      )
                                    })
                                    : null
                                }
                              </>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>

                    {/* First Aid Certifications End  */}
                    {/* Emergency Contact start */}
                    <h4 className="accordion-title js-accordion-title " >
                      Emergency Contact </h4>
                    <div className="accordion-content">
                      <div>
                        {contacts.map((contact, index) => (
                          <div className="row" key={index} style={{ marginBottom: "10px" }}>
                            <div className="col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input maxlength="20" value={contact.field_title} onChange={(e) => handleContactChange(index, "name", e)} type="text" className={contact.field_title ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="field_title" onBlur={(e) => e.target.setCustomValidity("")} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Name</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                            <div className="col-md-6">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input maxlength="20" value={contact.field_phone} onChange={(e) => handleContactChange(index, "phone", e)} type="text" className={contact.field_phone ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="field_phone" onBlur={(e) => e.target.setCustomValidity("")} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Phone</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={addEmergencyContactsApi} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>
                    {/* Emergency Contact and Medical Considerations ends here */}
                    <h4 className="accordion-title js-accordion-title " >
                    Medical Conditions and Considerations</h4>
                    <div className="accordion-content">
                      <div>
                        <h3> Please provide details below (e.g. - Condition, Asthma, Allergies, Anaphylaxis, Medications)</h3>
                        {medicalConditions.map((condition, index) => (
                          <div className="row">
                            <div key={`medical-condtion-${index}`} className="col-md-11">
                              <label className="mdc-text-field mdc-text-field--outlined">
                                <input value={condition} onChange={(e) => handleMedicalChange(index, e)} type="text" className={condition ? "mdc-text-field__input active" : "mdc-text-field__input"} aria-labelledby="my-label-id"
                                  id="condition" onBlur={(e) => e.target.setCustomValidity("")} />
                                <span className="mdc-notched-outline">
                                  <span className="mdc-notched-outline__leading"></span>
                                  <span className="mdc-notched-outline__notch">
                                    <span className="mdc-floating-label" id="my-label-id">Medical Conditions or Considerations</span>
                                  </span>
                                  <span className="mdc-notched-outline__trailing"></span>
                                </span>
                              </label>
                            </div>
                            {
                              index > 0 ?
                                <button style={{ width: '30px', height: '30px' }} type="button" className="mt-25" onClick={() => removeUrlField(index)}><span className="material-icons close">close</span></button>
                                :
                                null
                            }
                          </div>
                        ))}
                        <div className="custom-dashed-button mark-button-center" style={{ marginTop: "30px" }}>
                          <button className="mdc-button mdc-button--outlined btn-large" onClick={addMedicalCondition}>
                            <span className="mdc-button__ripple"></span>
                            <span className="mdc-button__label">Add More Medical Conditions</span>
                          </button>
                        </div>
                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>
                    {/* Emergency Contact and Medical Considerations ends here */}
                    <h4 className="accordion-title js-accordion-title">Blue Card</h4>
                    <div className="accordion-content">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 custom-col">
                          <label className="mdc-text-field mdc-text-field--outlined">
                            <input maxlength="30" value={blueCard} onChange={(e) => { setBlueCard(e.target.value); e.target.setCustomValidity(""); }} type="text" className={blueCard ? "mdc-text-field__input active" : "mdc-text-field__input"}
                              aria-labelledby="my-label-id" id="blueCardNumber" required onBlur={(e) => e.target.setCustomValidity("")} />
                            <span className="mdc-notched-outline">
                              <span className="mdc-notched-outline__leading"></span>
                              <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label" id="my-label-id">Blue Card Number*</span>
                              </span>
                              <span className="mdc-notched-outline__trailing"></span>
                            </span>
                          </label>
                        </div>
                        <div className="col-lg-4 col-md-6 custom-col">
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => blueCardCompletionCalendar()}>
                              <input value={blueCardCompletionDate ? moment(blueCardCompletionDate).format('DD-MM-YYYY') : blueCardCompletionDate} type="text" className={blueCardCompletionDate ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"
                                id="blueCardComletionDate" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Expiry Date*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                                <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            <div>
                              {
                                blueCardCompletionCal === true ? <Calendar onChange={blueCardCompletionDateHandler} /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 custom-col">
                          <div className="calendar-wrap">
                            <label className="mdc-text-field mdc-text-field--outlined" onClick={() => blueCardSignedCalendar()}>
                              <input value={blueCardSignedDate ? moment(blueCardSignedDate).format('DD-MM-YYYY') : blueCardSignedDate} type="text" className={blueCardSignedDate ? 'mdc-text-field__input active' : 'mdc-text-field__input'} aria-labelledby="my-label-id"
                                id="sightedDate" required onBlur={(e) => e.target.setCustomValidity("")} onkeypress="return false;" autoComplete="off" style={{ cursor: "pointer" }} />
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">Sighted Date*</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                                <span className="material-icons">calendar_today</span>
                              </span>
                            </label>
                            <div>
                              {
                                blueCardSignedCal === true ? <Calendar onChange={blueCardSignedDateHandler} /> : null
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-6 custom-col">
                          <div className="select-field">
                            <label className="mdc-text-field mdc-text-field--outlined dropdown-toggle">
                              <label className="reminder-field-style">Set Reminder*</label>
                              <span className="mdc-notched-outline">
                                <span className="mdc-notched-outline__leading"></span>
                                <span className="mdc-notched-outline__notch">
                                  <span className="mdc-floating-label" id="my-label-id">{blueCardReminder ? blueCardReminder : "Set Reminder"}</span>
                                </span>
                                <span className="mdc-notched-outline__trailing"></span>
                              </span>
                              <span className="material-icons">arrow_drop_down</span>
                            </label>
                            <ul className="dropdown">
                              <>
                                {
                                  reminder.map((item, index) =>
                                    <li onClick={() => setBlueCardReminder(item)} key={item}>{item}</li>
                                  )
                                }
                              </>
                            </ul>
                          </div>
                        </div>

                      </div>
                      <button className="mdc-button mdc-button--raised green-btn" type="submit" value='submit' onClick={saveProfile} >
                        <span className="mdc-button__label">Update</span>
                      </button>
                    </div>
                    <h4 className="accordion-title js-accordion-title">
                      Professional Development Logs
                    </h4>
                    {
                      professionalDevelop.length && professionalDevelop[0].field_task_status ? (
                        <>
                          <div className="accordion-content">
                            <div className="task-listing">
                              <TableContainer>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Date</TableCell>
                                      <TableCell>Method</TableCell>

                                      <TableCell>Course Name</TableCell>

                                      <TableCell>organisation</TableCell>

                                      <TableCell>Certificate</TableCell>
                                      {/* <TableCell>Timeframe</TableCell> */}
                                      {userRole == 2 ? (
                                        <TableCell>Modify</TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {professionalDevelop.map((data, index) => (
                                      data.field_date_completed ?
                                        <TableRow key={index} className="col-md-12">
                                          <TableCell>
                                            {data.field_date_completed ? (
                                              <Moment format="DD-MM-YYYY">
                                                {data.field_date_completed}
                                              </Moment>
                                            ) : null}
                                          </TableCell>
                                          <TableCell>
                                            {data.field_task_status}
                                          </TableCell>

                                          <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: data.field_course_name }} ></div>
                                          </TableCell>

                                          <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: data.field_organisation }} ></div>
                                          </TableCell>

                                          <TableCell onClick={() => { openDocument(data.field_new_upload_certificate ? (data.field_new_upload_certificate) : 'Not Available') }}>

                                            <figure>
                                              {data.field_new_upload_certificate ? <img style={{ cursor: 'pointer', height: 50, width: 50 }} src={(data.field_new_upload_certificate.split('.')[1]) === "pdf" ? pdfIcons :
                                                (data.field_new_upload_certificate.split('.')[1]) === "xslx" || (data.field_new_upload_certificate.split('.')[1]) === "xsl" ? excelIcons
                                                  : (data.field_new_upload_certificate.split('.')[1]) === "doc" || (data.field_new_upload_certificate.split('.')[1]) === "docx" ? docIcons
                                                    : ImageIcon} /> : "Not Available"}

                                            </figure>

                                          </TableCell>
                                          {
                                            userRole == 2 ?
                                              <TableCell>
                                                <button className="bg-green action-btn-style" onClick={() => editProfessional(data)}>Edit</button>
                                                <button className="bg-red action-btn-style del" onClick={() => deleteProfessioanlDevelop(data.id)} >Delete</button> </TableCell>
                                              : null
                                          }
                                        </TableRow>
                                        : null
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </>
                      ) : <h3>No Professional Development Logs Available</h3>}

                  </div>

                </div>

              </div>
            </div>

            {/* </form> */}
          </div>
        </div>
      </div>
      {/* ADD Professional Development Diaog */}
      <Dialog
        open={openProfessionalDevelopmentModal}
        onClose={() => setOpenProfessionalDevelopmentModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Professional Development Log
        </DialogTitle>
        <DialogContent>
          <div className="col-md-12">
            <div className="outlined-textfield-wrap mt-10">
              <form onSubmit={(e) => professionaSubmit(e, isEditPDL)}>
                <div className="row">
                  <div className="col-lg-6 col-md-6 mt-5">
                    <div className="calendar-wrap">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHidePDCalender("date")}
                      >
                        <input
                          value={professionalDevelopmentStates.date !== '' ? moment(professionalDevelopmentStates.date).format('DD-MM-YYYY') : ''}
                          onkeypress="return false;"
                          autoComplete="off"
                          type="text"
                          className={
                            professionalDevelopmentStates.date
                              ? "mdc-text-field__input active"
                              : "mdc-text-field__input"
                          }
                          aria-labelledby="my-label-id"
                          id="completedDate"
                          required
                          onBlur={(e) => e.target.setCustomValidity("")} style={{ cursor: "pointer" }}
                        />
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              Date Completed *
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                          <span className="material-icons">calendar_today</span>
                        </span>
                      </label>
                      <div>
                        {professionalDevelopmentStates.showDateCalender ===
                          true ? (
                          <Calendar
                            onChange={(date) => onChangePDDate(date, "date")}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <div className="select-field">
                      <label
                        className="mdc-text-field mdc-text-field--outlined"
                        onClick={() => showHideDropdown("pdMethods")}
                      >
                        <span className="mdc-notched-outline">
                          <span className="mdc-notched-outline__leading"></span>
                          <span className="mdc-notched-outline__notch">
                            <span
                              className="mdc-floating-label"
                              id="my-label-id"
                            >
                              {professionalDevelopmentStates.method
                                ? professionalDevelopmentStates.method
                                : "Method *"}
                            </span>
                          </span>
                          <span className="mdc-notched-outline__trailing"></span>
                        </span>
                        <span className="material-icons">arrow_drop_down</span>
                      </label>
                      {professionalDevelopmentStates.showMethod === true ? (
                        <ul className="dropdown-2">
                          {professionalDevelopmentMethods.map((data) => (
                            <li
                              onClick={() =>
                                setProfessionalDevelopmentStates({
                                  ...professionalDevelopmentStates,
                                  method: data,
                                  showMethod: false,
                                })
                              }
                            >
                              {data}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.courseName
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="courseName"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.courseName}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            courseName: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Course Name *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>

                  <div className="col-lg-6 col-md-6 custom-col mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        className={
                          professionalDevelopmentStates.organisation
                            ? "mdc-text-field__input active"
                            : "mdc-text-field__input"
                        }
                        type="text"
                        name="completionTimeframe"
                        aria-labelledby="my-label-id"
                        value={professionalDevelopmentStates.organisation}
                        onChange={(e) => {
                          setProfessionalDevelopmentStates({
                            ...professionalDevelopmentStates,
                            organisation: e.target.value,
                          });
                          e.target.setCustomValidity("");
                        }}
                        id="completionTimeframe"
                        required
                        onBlur={(e) => e.target.setCustomValidity("")}
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Organisation *
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                    </label>
                  </div>

                  <div className="col-lg-6 col-md-6 mt-5">
                    <label className="mdc-text-field mdc-text-field--outlined">
                      <input
                        onChange={(e) => uploadImages(e)}
                        type="file"
                        accept="image/jpg,image/jpeg,image/png,.pdf"
                        id="attach-file"
                        className="mdc-text-field__input"
                        aria-labelledby="my-label-id"
                      />
                      <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"></span>
                        <span className="mdc-notched-outline__notch">
                          <span className="mdc-floating-label" id="my-label-id">
                            Upload Certificate
                          </span>
                        </span>
                        <span className="mdc-notched-outline__trailing"></span>
                      </span>
                      <span className="material-icons">image</span>
                    </label>
                    <small className="short-description mt-25">
                      Image can only be of the following types : jpg, jpeg, pdf
                      png with limit upto 5MB
                    </small>
                    <>
                      {professionalDevelopmentStates.certificate.length > 0
                        ? professionalDevelopmentStates.certificate.map(

                          (data, index) => {
                            let splittedArray = data.name.split(".")
                            let uploadedImageIcon = splittedArray[1] === "pdf" ? pdfIcons :
                              splittedArray[1] === "xslx" || splittedArray[1] === "xsl" ? excelIcons
                                : splittedArray[1] === "doc" || splittedArray[1] === "docx" ? docIcons
                                  : data.path
                            return (
                              <>
                                <img height="100" width="100" src={uploadedImageIcon} alt=''/>
                                <button
                                  type="button"
                                  onClick={(e) => {
                                    removeSpecificImage(index);
                                  }}
                                >
                                  <span className="material-icons close">
                                    close
                                  </span>
                                </button>
                              </>
                            )
                          }
                        )
                        : null}
                    </>
                  </div>


                  <div className="col-lg-12 col-md-12 custom-col error_msg_shw">
                    <small className="text-danger">
                      {error.methodError}
                    </small>
                  </div>
                </div>
                <DialogActions>
                  <button
                    type="button"
                    class="mdc-button mdc-button--raised green-btn"
                    onClick={() => {
                      setOpenProfessionalDevelopmentModal(false);
                      // setProfessionalDevelopmentStates(emptyProfessionalLogs)
                      setProfessionalDevelopmentStates(initialPDStates);
                    }}
                  >
                    <span class="mdc-button__label">Cancel</span>
                  </button>
                  <button class="mdc-button mdc-button--raised">
                    <span class="mdc-button__label">Submit</span>
                  </button>
                </DialogActions>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default EditHeadOfficeProfilePage;